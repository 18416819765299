<template>
  <div class="trusteeship-child-add-edit">
    <div class="scroll-content">
      <van-form
        ref="add_edit_form"
        class="add-edit-form"
        input-align="right"
        error-message-align="right"
        validate-trigger="onSubmit"
        :readonly="false"
        :colon="true"
        :scroll-to-error="true"
        :show-error="false"
        :show-error-message="true"
        :submit-on-enter="false"
        label-width="6.4em"
        @submit="submitOnHandle"
      >
        <van-field
          required
          v-model="form.parentsName"
          label="家长姓名"
          placeholder="请输入家长姓名"
          :rules="[{ required: true, message: '家长姓名不能为空' }]"
        />
        <van-field
          required
          v-model="form.parentsPhone"
          label="家长手机号"
          placeholder="请输入家长手机号"
          :rules="[
            { required: true, message: '家长手机号不能为空' },
            {
              pattern: /^(?:(?:\+|00)86)?1[3-9]\d{9}$/,
              message: '手机号码不正确',
            },
          ]"
        />
        <van-field
          required
          v-model="form.childrenName"
          label="幼儿姓名"
          placeholder="请输入幼儿姓名"
          :rules="[{ required: true, message: '幼儿姓名不能为空' }]"
        />
        <van-field
          required
          v-model.number="form.childrenAge"
          type="number"
          label="幼儿年龄"
          placeholder="请输入幼儿年龄"
          :rules="[{ required: true, message: '幼儿年龄不能为空' }]"
        />
        <van-field
          readonly
          required
          v-model="form.kindergartenBeginTime"
          label="托幼周期开始"
          placeholder="请选择托幼周期开始"
          :clickable="true"
          right-icon="notes-o"
          :rules="[{ required: true, message: '托幼周期开始不能为空' }]"
          @click="startTimeOnClick"
        />
        <van-field
          class="tyzqjs"
          readonly
          required
          v-model="form.kindergartenEndTime"
          label="托幼周期结束"
          placeholder="请选择托幼周期结束"
          :clickable="true"
          right-icon="notes-o"
          :rules="[{ required: true, message: '托幼周期结束不能为空' }]"
          @click="endTimeOnClick"
        />
      </van-form>
    </div>
    <div class="footer-handle">
      <van-button
        v-if="[EXAMINE_WAIT, EXAMINE_REFUSE].includes(auditStatus)"
        class="submit-btn"
        type="info"
        native-type="button"
        color="#ef6c6c"
        :loading="submitLoading"
        @click="submitOnClick"
      >
        提交
      </van-button>
    </div>

    <v-dateTimePicker
      type="date"
      :isAuto="true"
      :value="startDate"
      :minDate="startMinDate"
      :maxDate="startMaxDate"
      :valueShow.sync="startTimeShow"
      @confirmPicker="startTimeOnOk"
    />
    <v-dateTimePicker
      type="date"
      :isAuto="true"
      :value="endDate"
      :minDate="endMinDate"
      :maxDate="endMaxDate"
      :valueShow.sync="endTimeShow"
      @confirmPicker="endTimeOnOk"
    />
  </div>
</template>

<script>
import { saveTyyyInfoUrl, getTyyyDetailUrl, userInfoURL } from "./api.js";
import { EXAMINE_PASS, EXAMINE_WAIT, EXAMINE_REFUSE } from "./map.js";
import { Form, Button } from "vant";
import { mapState } from "vuex";
import moment from "moment";
import _ from "lodash";

export default {
  name: "trusteeshipChildAddEdit",
  components: {
    [Form.name]: Form,
    [Button.name]: Button,
  },
  props: {},
  model: {},
  data() {
    return {
      EXAMINE_WAIT,
      EXAMINE_PASS,
      EXAMINE_REFUSE,
      auditStatus: EXAMINE_WAIT,
      form: {
        userId: "",
        tenantId: "",
        id: "",
        parentsName: "",
        parentsPhone: "",
        childrenName: "",
        childrenAge: "",
        kindergartenBeginTime: "",
        kindergartenEndTime: "",
        auditStatus: EXAMINE_WAIT,
      },
      startTimeShow: false,
      startDate: new Date(),
      startMinDate: null,
      startMaxDate: null,
      endTimeShow: false,
      endDate: new Date(),
      endMinDate: null,
      endMaxDate: null,
      submitLoading: false,
      tyzqjsErrorText: false,
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  watch: {},
  created() {
    if (!["", null, void 0, NaN].includes(this.$route.query.id)) {
      this.getDetail();
    } else {
    }
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    getDetail() {
      this.$axios
        .get(getTyyyDetailUrl, { params: { id: this.$route.query.id } })
        .then((res) => {
          if (res && res.code === 200) {
            res.data = res.data || {};
            Object.keys(this.form).forEach((key) => {
              this.form[key] = res.data[key] ?? "";
            });
            this.auditStatus = res.data.auditStatus;
          }
        });
    },
    startTimeOnClick() {
      const currentDate = new Date();
      if (
        this.form.kindergartenEndTime &&
        /^\d{4}-\d{2}-\d{2}$/.test(this.form.kindergartenEndTime)
      ) {
        this.startMaxDate = new Date(
          this.form.kindergartenEndTime.replace(/-/g, "/")
        );
      } else {
        this.startMaxDate = new Date(
          currentDate.getFullYear() + 10,
          currentDate.getMonth(),
          currentDate.getDate()
        );
      }
      const minDate =
        currentDate > this.startMaxDate ? this.startMaxDate : currentDate;
      this.startMinDate = new Date(
        minDate.getFullYear() - 1,
        currentDate.getMonth(),
        currentDate.getDate()
      );
      if (
        this.form.kindergartenBeginTime &&
        /^\d{4}-\d{2}-\d{2}$/.test(this.form.kindergartenBeginTime)
      ) {
        this.startDate = new Date(
          this.form.kindergartenBeginTime.replace(/-/g, "/")
        );
      }
      this.startTimeShow = true;
    },
    startTimeOnOk(value) {
      if (!value) return;
      this.form.kindergartenBeginTime = moment(value).format("YYYY-MM-DD");
    },
    endTimeOnClick() {
      const currentDate = new Date();
      if (
        this.form.kindergartenBeginTime &&
        /^\d{4}-\d{2}-\d{2}$/.test(this.form.kindergartenBeginTime)
      ) {
        this.endMinDate = new Date(
          this.form.kindergartenBeginTime.replace(/-/g, "/")
        );
      } else {
        this.endMinDate = new Date(
          currentDate.getFullYear() - 1,
          currentDate.getMonth(),
          currentDate.getDate()
        );
      }
      this.endMaxDate = new Date(
        this.endMinDate.getFullYear() + 10,
        this.endMinDate.getMonth(),
        this.endMinDate.getDate()
      );
      if (
        this.form.kindergartenEndTime &&
        /^\d{4}-\d{2}-\d{2}$/.test(this.form.kindergartenEndTime)
      ) {
        this.endDate = new Date(
          this.form.kindergartenEndTime.replace(/-/g, "/")
        );
      }
      this.endTimeShow = true;
    },
    endTimeOnOk(value) {
      if (!value) return;
      this.form.kindergartenEndTime = moment(value).format("YYYY-MM-DD");
    },
    submitOnClick() {
      this.$refs.add_edit_form.submit();
    },
    submitOnHandle() {
      if (!this.tyzqjsCheck()) return;
      this.submitLoading = true;
      const form = _.cloneDeep(this.form);
      form.userId = this.userId;
      form.tenantId = this.tenantId;
      form.auditStatus = EXAMINE_WAIT;
      if (["", null, void 0, NaN].includes(form.id)) {
        Reflect.deleteProperty(form, "id");
      }
      this.$axios
        .post(saveTyyyInfoUrl, form)
        .then((res) => {
          if (res && res.code == 200) {
            this.$toast(res.msg);
            this.$router.go(-1);
          }
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
    tyzqjsCheck() {
      if (
        new Date(this.form.kindergartenBeginTime.replace(/-/g, "/")) <=
        new Date(this.form.kindergartenEndTime.replace(/-/g, "/"))
      ) {
        this.tyzqjsErrorText = "";
        return true;
      } else {
        this.tyzqjsErrorText = "结束时间必须大于等于开始时间";
        this.$toast(this.tyzqjsErrorText);
        // document.querySelector('.tyzqjs').scrollIntoView({ behavior: 'instant', block: 'start', inline: 'start' });
        return false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
@height: 128px;
.trusteeship-child-add-edit {
  box-sizing: border-box;
  height: 100vh;
  background-color: #f9f9f9;
  padding-bottom: 0px;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}
.scroll-content {
  box-sizing: border-box;
  padding: 20px;
  max-height: calc(100% - @height);
  overflow-x: hidden;
  overflow-y: auto;
}
.add-edit-form {
  border-radius: 16px;
  overflow: hidden;
  background-color: #ffffff;
  box-shadow: 0px 4px 16px 0px rgba(227, 227, 227, 0.5);
}
.footer-handle {
  box-sizing: border-box;
  width: 100vw;
  height: @height;
  padding: 20px;
  position: fixed;
  left: 0px;
  bottom: 0px;
  .submit-btn {
    box-sizing: border-box;
    width: 100%;
    height: 88px;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 32px;
  }
}
</style>
